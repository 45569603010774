/* glue: 0.13 hash: 9c03097b82 */

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A3:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: 0 0;
    width: 55px;
    height: 159px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A3 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -55px 0;
    width: 55px;
    height: 159px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A4:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -110px 0;
    width: 55px;
    height: 122px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A4 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -165px 0;
    width: 55px;
    height: 122px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A3-mini-3-in-1:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -220px 0;
    width: 63px;
    height: 99px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A3-mini-3-in-1 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: 0 -159px;
    width: 63px;
    height: 99px;
  }

  .sprite-page_calendars-wall-changeover-calendar_wall-changeover-sided-A3:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -63px -159px;
    width: 60px;
    height: 84px;
  }

  .sprite-page_calendars-wall-changeover-calendar_wall-changeover-sided-A3 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -123px -159px;
    width: 60px;
    height: 84px;
  }

  .sprite-page_calendars-wall-changeover-calendar_wall-changeover-double-sided-A3:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -183px -159px;
    width: 60px;
    height: 82px;
  }

  .sprite-page_calendars-wall-changeover-calendar_wall-changeover-double-sided-A3 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -283px 0;
    width: 60px;
    height: 82px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A4-mini-3-in-1:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -283px -82px;
    width: 37px;
    height: 82px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A4-mini-3-in-1 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -283px -164px;
    width: 37px;
    height: 82px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A4-two-page:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: 0 -258px;
    width: 54px;
    height: 75px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A4-two-page {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -54px -258px;
    width: 54px;
    height: 75px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A6:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -243px -159px;
    width: 25px;
    height: 68px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A6 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -108px -258px;
    width: 25px;
    height: 68px;
  }

  .sprite-page_calendars-desktop-calendar_table-house-A5-3-in-1:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -133px -258px;
    width: 65px;
    height: 34px;
  }

  .sprite-page_calendars-desktop-calendar_table-house-A5-3-in-1 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -198px -258px;
    width: 65px;
    height: 34px;
  }

  .sprite-page_calendars-desktop-calendar_table-house-3-in-1:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -263px -258px;
    width: 65px;
    height: 34px;
  }

  .sprite-page_calendars-desktop-calendar_table-house-3-in-1 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -133px -292px;
    width: 65px;
    height: 34px;
  }

  .sprite-page_calendars-desktop-calendar_table-horizontal-house:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -198px -292px;
    width: 65px;
    height: 32px;
  }

  .sprite-page_calendars-desktop-calendar_table-horizontal-house {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -263px -292px;
    width: 65px;
    height: 32px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A5-3-in-1:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -343px 0;
    width: 37px;
    height: 62px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A5-3-in-1 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -343px -62px;
    width: 37px;
    height: 62px;
  }

  .sprite-page_calendars-desktop-calendar_book-pinwheel:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -343px -124px;
    width: 29px;
    height: 62px;
  }

  .sprite-page_calendars-desktop-calendar_book-pinwheel {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -343px -186px;
    width: 29px;
    height: 62px;
  }

  .sprite-page_calendars-wall-changeover-calendar_wall-changeover-sided-A4:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -220px -99px;
    width: 43px;
    height: 60px;
  }

  .sprite-page_calendars-wall-changeover-calendar_wall-changeover-sided-A4 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -380px 0;
    width: 43px;
    height: 60px;
  }

  .sprite-page_calendars-wall-changeover-calendar_wall-changeover-double-sided-A4:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -380px -60px;
    width: 43px;
    height: 58px;
  }

  .sprite-page_calendars-wall-changeover-calendar_wall-changeover-double-sided-A4 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -380px -118px;
    width: 43px;
    height: 58px;
  }

  .sprite-page_calendars-desktop-calendar_table-vertical-house:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -380px -176px;
    width: 31px;
    height: 57px;
  }

  .sprite-page_calendars-desktop-calendar_table-vertical-house {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -380px -233px;
    width: 31px;
    height: 57px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A4-3-in-1:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -343px -248px;
    width: 37px;
    height: 55px;
  }

  .sprite-page_calendars-quarterly-calendar_quarterly-calendar-A4-3-in-1 {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: 0 -333px;
    width: 37px;
    height: 55px;
  }

  .sprite-page_calendars-pocket-calendar_pocket-vertical:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -37px -333px;
    width: 33px;
    height: 45px;
  }

  .sprite-page_calendars-pocket-calendar_pocket-vertical {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -70px -333px;
    width: 33px;
    height: 45px;
  }

  .sprite-page_calendars-pocket-calendar_pocket-horizontal:before {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -165px -122px;
    width: 45px;
    height: 33px;
  }

  .sprite-page_calendars-pocket-calendar_pocket-horizontal {
    background-image: url('img/sprites/page_calendars.png');
    background-repeat: no-repeat;
    background-size: 423px 388px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_calendars.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_calendars@2x.png');
    }
    
    background-position: -110px -122px;
    width: 45px;
    height: 33px;
  }
