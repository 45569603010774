.cart-confirm {
  width: 960px;
  margin: 25px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    text-transform: uppercase;
    font-family: $germ;
  }
  .contacts {
    font-size: 24px;
    text-align: right;
    p { font-size: 14px; }
  }
}
.order.labs {
  font: 15px $germ;
  width: 100%;
  max-width: 930px;
  margin: 0 auto;
  padding: 15px;
  border: 1px solid #b2b8bc;
  text-align: center;
  .specials {
    width: 100%;
    border-collapse: collapse;
    td,
    th {
      border: 1px solid $grey_font_light;
      font-size: 14px;
      font-family: $germ;
      padding: 5px 0;
      width: 50%;
    }
    th { font-size: 18px; }
  }
  &.logistics {
    max-width: 1200px;

    .select2 {
      width: 300px !important;
      margin-bottom: 20px;
    }

    .order-delivery {
      margin: 0 auto;
      .js-tabs li { line-height: 1.2; }
      .js-delivery .js-content {
        border: 3px solid #d3d3d3;
        border-top: none;
      }
      .js-content .prepayment-table td { padding: 4px 0; }
    }

    .button_submit {
      @include button($orange,$orange_hover);
    }
  }
  .green { color: $green; }
  .red { color: $red; }
  .orange { color: $orange; }
  .warning-refresh {
    position: fixed;
    width: 900px;
    bottom: 0;
    font-size: 25px;
  }
  .bordered {
    border-top: 1px solid #b2b8bc;
    margin-top: 20px;
    > h3 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 20px;
      margin-top: -10px;
      span {
        font-family: $germ;
        background: #fff;
        padding: 0 10px;
        color: #222527;
      }
    }
  }
  .lab-order-list {
    width: 100%;
    border-collapse: collapse;
    td,
    th {
      border: 3px solid gray;
      padding: 10px;
      font-family: $c35;
      .set-inprogress {
        background-color: #00f;
      }
      .select2 {
        width: 100% !important;
        min-width: 150px;
        font-size: 16px;
      }
      a {
        color: blue;
        text-decoration: underline;
      }
      strong { font-family: $c55; }
    }
  }
  .drive {
    @include button(#77f,#77f);
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    text-decoration: underline;
    text-transform: lowercase;
    &:hover { text-decoration: none; }
  }
  .js-tabs {
    display: flex;
    height: 36px;
    font-size: 0;
    margin-bottom: 10px;
    margin-left: 0;
    li {
      background-color: #f0f4f6;
      cursor: pointer;
      display: inline-block;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border: 1px solid transparent;
      border-bottom: 1px solid #b2b8bc;
      margin-left: 0;
      border-spacing: 0;
      border-collapse: collapse;
      box-sizing: border-box;
      flex: 1 1 auto;
      font-size: initial;
      text-transform: uppercase;
      color: #222527;
      &.active {
        background-color: #fff;
        border: 1px solid #b2b8bc;
        border-bottom: 1px solid transparent;
      }
    }
  }
  .counter-orders {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: $red;
    border-radius: 30px;
    float: right;
    line-height: 20px;
    margin-top: 7px;
    margin-left: 5px;
    color: #fff;
  }
  .js-content {
    margin-left: 0;
    padding: 0 30px;
    &.change-types-delivery {
      padding: 30px;
      border: 1px solid #b2b8bc;
      border-bottom: none;
    }
    table tr.delivery-data-fields > td { padding: 8px 0; }
    .prepayment-table {
      width: 535px;
      margin-left: 35px;
      td { padding: 4px 10px; }
    }
    .provider-tr.active .hidden-if-active { display: none; }
  }
  .js-point-select-link {
    font-size: 11px;
    text-decoration: none;
    border-bottom: 1px dashed #888;
    white-space: nowrap;
    clear: both;
    color: #888;
    &:hover {
      color: #777;
      border-bottom-color: #777;
    }
    visibility: hidden;
    .active & { visibility: visible; }
  }
  .delivery-error {
    margin-left: 213px;
    text-align: center;
    font-size: 20px !important;
  }
  .point-label {
    display: block;
    width: 100% !important;
    clear: both;
    text-align: left;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
  form{
    margin-top: 10px;
  }
  label{
    
    font-size: 20px;
  }
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  width: 30%; 
  font-size: 20px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}