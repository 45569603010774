/* glue: 0.13 hash: c177d53db0 */

  .sprite-sprite_images-logo {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: 0 0;
    width: 160px;
    height: 112px;
  }

  .sprite-sprite_images-payment {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: 0 -112px;
    width: 119px;
    height: 26px;
  }

  .sprite-sprite_images-order_complete_logo {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -160px 0;
    width: 101px;
    height: 32px;
  }

  .sprite-sprite_images-balloon {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -160px -32px;
    width: 71px;
    height: 81px;
  }

  .sprite-sprite_images-status_download_default {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: 0 -138px;
    width: 76px;
    height: 29px;
  }

  .sprite-sprite_images-status_download_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -76px -138px;
    width: 76px;
    height: 29px;
  }

  .sprite-sprite_images-dont_buy {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: 0 -167px;
    width: 70px;
    height: 65px;
  }

  .sprite-sprite_images-delivery_default {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -261px 0;
    width: 59px;
    height: 67px;
  }

  .sprite-sprite_images-delivery_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -261px -67px;
    width: 59px;
    height: 67px;
  }

  .sprite-sprite_images-quality_default {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -261px -134px;
    width: 48px;
    height: 62px;
  }

  .sprite-sprite_images-quality_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -70px -167px;
    width: 48px;
    height: 62px;
  }

  .sprite-sprite_images-money_default {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -118px -167px;
    width: 47px;
    height: 61px;
  }

  .sprite-sprite_images-money_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -165px -167px;
    width: 47px;
    height: 61px;
  }

  .sprite-sprite_images-return_default {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -212px -167px;
    width: 46px;
    height: 60px;
  }

  .sprite-sprite_images-return_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: 0 -232px;
    width: 46px;
    height: 60px;
  }

  .sprite-sprite_images-loader_picture {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -160px -113px;
    width: 60px;
    height: 19px;
  }

  .sprite-sprite_images-delivery {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -46px -232px;
    width: 59px;
    height: 57px;
  }

  .sprite-sprite_images-next_cell_3 {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -105px -232px;
    width: 56px;
    height: 55px;
  }

  .sprite-sprite_images-next_cell_2 {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -161px -232px;
    width: 56px;
    height: 55px;
  }

  .sprite-sprite_images-next_cell_1 {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -217px -232px;
    width: 56px;
    height: 55px;
  }

  .sprite-sprite_images-garant {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -320px 0;
    width: 47px;
    height: 56px;
  }

  .sprite-sprite_images-editor {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -320px -56px;
    width: 34px;
    height: 56px;
  }

  .sprite-sprite_images-carrotquest {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: 0 -292px;
    width: 50px;
    height: 50px;
  }

  .sprite-sprite_images-add_review {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -50px -292px;
    width: 50px;
    height: 50px;
  }

  .sprite-sprite_images-money {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -320px -112px;
    width: 46px;
    height: 46px;
  }

  .sprite-sprite_images-email_rss {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -320px -158px;
    width: 41px;
    height: 38px;
  }

  .sprite-sprite_images-photo_print_select {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -320px -196px;
    width: 38px;
    height: 28px;
  }

  .sprite-sprite_images-drop_area {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -320px -224px;
    width: 35px;
    height: 31px;
  }

  .sprite-sprite_images-present {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -320px -255px;
    width: 32px;
    height: 30px;
  }

  .sprite-sprite_images-ic-2 {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -261px -196px;
    width: 30px;
    height: 12px;
  }

  .sprite-sprite_images-ic-1 {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -261px -208px;
    width: 30px;
    height: 18px;
  }

  .sprite-sprite_images-coupon {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -231px -32px;
    width: 30px;
    height: 14px;
  }

  .sprite-sprite_images-close {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -231px -46px;
    width: 30px;
    height: 30px;
  }

  .sprite-sprite_images-add_photo_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -231px -76px;
    width: 30px;
    height: 28px;
  }

  .sprite-sprite_images-add_photo {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -152px -138px;
    width: 30px;
    height: 28px;
  }

  .sprite-sprite_images-warning {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -182px -138px;
    width: 26px;
    height: 26px;
  }

  .sprite-sprite_images-spread {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -291px -208px;
    width: 26px;
    height: 16px;
  }

  .sprite-sprite_images-quality-warning {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -208px -138px;
    width: 26px;
    height: 26px;
  }

  .sprite-sprite_images-vk_hover {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -119px -112px;
    width: 25px;
    height: 25px;
  }

  .sprite-sprite_images-vk {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -234px -138px;
    width: 25px;
    height: 25px;
  }

  .sprite-sprite_images-instagram_hover {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -273px -232px;
    width: 25px;
    height: 25px;
  }

  .sprite-sprite_images-instagram {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -273px -257px;
    width: 25px;
    height: 25px;
  }

  .sprite-sprite_images-inst_sub {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -100px -292px;
    width: 25px;
    height: 25px;
  }

  .sprite-sprite_images-free_delivery {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -220px -113px;
    width: 25px;
    height: 14px;
  }

  .sprite-sprite_images-facebook_hover {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -125px -292px;
    width: 25px;
    height: 25px;
  }

  .sprite-sprite_images-vk_popup {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -150px -292px;
    width: 24px;
    height: 14px;
  }

  .sprite-sprite_images-twitter_popup {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -100px -317px;
    width: 24px;
    height: 20px;
  }

  .sprite-sprite_images-ribbon {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -124px -317px;
    width: 24px;
    height: 15px;
  }

  .sprite-sprite_images-odnoklasniki_popup {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -352px -255px;
    width: 14px;
    height: 24px;
  }

  .sprite-sprite_images-instagram_popup {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: 0 -342px;
    width: 24px;
    height: 24px;
  }

  .sprite-sprite_images-facebook_popup {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -354px -56px;
    width: 11px;
    height: 24px;
  }

  .sprite-sprite_images-youtube {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -24px -342px;
    width: 23px;
    height: 16px;
  }

  .sprite-sprite_images-quantity_spread {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px 0;
    width: 23px;
    height: 19px;
  }

  .sprite-sprite_images-ic-5 {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -19px;
    width: 23px;
    height: 18px;
  }

  .sprite-sprite_images-header_cart {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -37px;
    width: 22px;
    height: 18px;
  }

  .sprite-sprite_images-star_orange {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -55px;
    width: 21px;
    height: 19px;
  }

  .sprite-sprite_images-star_grey {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -74px;
    width: 21px;
    height: 19px;
  }

  .sprite-sprite_images-star_blue {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -93px;
    width: 21px;
    height: 19px;
  }

  .sprite-sprite_images-satin {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -112px;
    width: 21px;
    height: 15px;
  }

  .sprite-sprite_images-heart_full {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -127px;
    width: 21px;
    height: 19px;
  }

  .sprite-sprite_images-heart {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -146px;
    width: 21px;
    height: 19px;
  }

  .sprite-sprite_images-arrow_promoblock {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -165px;
    width: 20px;
    height: 21px;
  }

  .sprite-sprite_images-search {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -186px;
    width: 20px;
    height: 20px;
  }

  .sprite-sprite_images-header_tel_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -206px;
    width: 7px;
    height: 20px;
  }

  .sprite-sprite_images-header_tel {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -374px -206px;
    width: 7px;
    height: 20px;
  }

  .sprite-sprite_images-device_popup {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -226px;
    width: 20px;
    height: 20px;
  }

  .sprite-sprite_images-archive_popup {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -246px;
    width: 20px;
    height: 17px;
  }

  .sprite-sprite_images-upload {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -263px;
    width: 19px;
    height: 17px;
  }

  .sprite-sprite_images-ic-4 {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -280px;
    width: 19px;
    height: 18px;
  }

  .sprite-sprite_images-ic-3 {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -298px;
    width: 16px;
    height: 18px;
  }

  .sprite-sprite_images-coupon_mini_orange {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -316px;
    width: 18px;
    height: 10px;
  }

  .sprite-sprite_images-coupon_mini {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -326px;
    width: 18px;
    height: 10px;
  }

  .sprite-sprite_images-star_blue_detail {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -336px;
    width: 17px;
    height: 15px;
  }

  .sprite-sprite_images-paper {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -367px -351px;
    width: 17px;
    height: 14px;
  }

  .sprite-sprite_images-instagram_review {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -298px -232px;
    width: 17px;
    height: 17px;
  }

  .sprite-sprite_images-delivery_box_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -144px -112px;
    width: 15px;
    height: 17px;
  }

  .sprite-sprite_images-delivery_box {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -298px -257px;
    width: 15px;
    height: 17px;
  }

  .sprite-sprite_images-timer {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -47px -342px;
    width: 16px;
    height: 16px;
  }

  .sprite-sprite_images-fastening {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -354px -80px;
    width: 12px;
    height: 16px;
  }

  .sprite-sprite_images-consultant {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -148px -317px;
    width: 16px;
    height: 15px;
  }

  .sprite-sprite_images-stretcher {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -164px -317px;
    width: 15px;
    height: 15px;
  }

  .sprite-sprite_images-photo_print_edit {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -179px -317px;
    width: 15px;
    height: 15px;
  }

  .sprite-sprite_images-photo_print_delete {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -194px -317px;
    width: 15px;
    height: 15px;
  }

  .sprite-sprite_images-header_city_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -354px -96px;
    width: 11px;
    height: 15px;
  }

  .sprite-sprite_images-header_city {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -355px -224px;
    width: 11px;
    height: 15px;
  }

  .sprite-sprite_images-header_acc_active {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -355px -239px;
    width: 11px;
    height: 15px;
  }

  .sprite-sprite_images-header_acc {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -309px -134px;
    width: 11px;
    height: 15px;
  }

  .sprite-sprite_images-clock {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -63px -342px;
    width: 15px;
    height: 15px;
  }

  .sprite-sprite_images-checkout_box {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -78px -342px;
    width: 14px;
    height: 15px;
  }

  .sprite-sprite_images-agreement {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -92px -342px;
    width: 15px;
    height: 15px;
  }

  .sprite-sprite_images-height {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -381px -206px;
    width: 5px;
    height: 14px;
  }

  .sprite-sprite_images-check {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -291px -196px;
    width: 14px;
    height: 9px;
  }

  .sprite-sprite_images-presence {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -245px -113px;
    width: 13px;
    height: 13px;
  }

  .sprite-sprite_images-width {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -352px -279px;
    width: 12px;
    height: 5px;
  }

  .sprite-sprite_images-unable {
    background-image: url('img/sprites/sprite_images.png');
    background-repeat: no-repeat;
    background-size: 390px 366px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_images.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_images@2x.png');
    }
    
    background-position: -174px -292px;
    width: 12px;
    height: 12px;
  }
