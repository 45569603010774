/* glue: 0.13 hash: 591a801a74 */

  .sprite-sprite_nav_img-photo_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: 0 0;
    width: 24px;
    height: 31px;
  }

  .sprite-sprite_nav_img-photo_icon-hover {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -24px 0;
    width: 24px;
    height: 31px;
  }

  .sprite-sprite_nav_img-photo_icon-active {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -48px 0;
    width: 24px;
    height: 31px;
  }

  .sprite-sprite_nav_img-books_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: 0 -31px;
    width: 31px;
    height: 27px;
  }

  .sprite-sprite_nav_img-books_icon-hover {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -31px -31px;
    width: 31px;
    height: 27px;
  }

  .sprite-sprite_nav_img-books_icon-active {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -72px 0;
    width: 31px;
    height: 27px;
  }

  .sprite-sprite_nav_img-souvenirs_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -72px -27px;
    width: 27px;
    height: 28px;
  }

  .sprite-sprite_nav_img-souvenirs_icon-hover {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: 0 -58px;
    width: 27px;
    height: 28px;
  }

  .sprite-sprite_nav_img-souvenirs_icon-active {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -27px -58px;
    width: 27px;
    height: 28px;
  }

  .sprite-sprite_nav_img-polygraphy_icon_sidebar {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -54px -58px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-polygraphy_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -77px -58px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-polygraphy_icon-hover {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -103px 0;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-polygraphy_icon-active {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -103px -28px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-photocanvas_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -103px -56px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-photocanvas_icon-hover {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: 0 -86px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-photocanvas_icon-active {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -23px -86px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-clothes_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -46px -86px;
    width: 28px;
    height: 28px;
  }

  .sprite-sprite_nav_img-clothes_icon-hover {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -74px -86px;
    width: 28px;
    height: 28px;
  }

  .sprite-sprite_nav_img-clothes_icon-active {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -126px 0;
    width: 28px;
    height: 28px;
  }

  .sprite-sprite_nav_img-calendars_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -126px -28px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-calendars_icon-hover {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -126px -56px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-calendars_icon-active {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -126px -84px;
    width: 23px;
    height: 28px;
  }

  .sprite-sprite_nav_img-accessories_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -102px -86px;
    width: 18px;
    height: 28px;
  }

  .sprite-sprite_nav_img-accessories_icon-hover {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: 0 -114px;
    width: 18px;
    height: 28px;
  }

  .sprite-sprite_nav_img-accessories_icon-active {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -18px -114px;
    width: 18px;
    height: 28px;
  }

  .sprite-sprite_nav_img-chioce_present_icon {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -36px -114px;
    width: 23px;
    height: 20px;
  }

  .sprite-sprite_nav_img-books_icon_sidebar {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -59px -114px;
    width: 23px;
    height: 20px;
  }

  .sprite-sprite_nav_img-souvenirs_icon_sidebar {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -82px -114px;
    width: 20px;
    height: 20px;
  }

  .sprite-sprite_nav_img-photocanvas_icon_sidebar {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -102px -114px;
    width: 16px;
    height: 20px;
  }

  .sprite-sprite_nav_img-photo_icon_sidebar {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -118px -114px;
    width: 18px;
    height: 20px;
  }

  .sprite-sprite_nav_img-clothes_icon_sidebar {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -154px 0;
    width: 20px;
    height: 20px;
  }

  .sprite-sprite_nav_img-calendars_icon_sidebar {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -154px -20px;
    width: 17px;
    height: 20px;
  }

  .sprite-sprite_nav_img-accessories_icon_sidebar {
    background-image: url('img/sprites/sprite_nav_img.png');
    background-repeat: no-repeat;
    background-size: 174px 142px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/sprite_nav_img.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/sprite_nav_img@2x.png');
    }
    
    background-position: -154px -40px;
    width: 13px;
    height: 20px;
  }
