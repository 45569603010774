/* glue: 0.13 hash: 74864d6586 */

  .sprite-page_canvas-modular-pictures_90x80-modular-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: 0 0;
    width: 120px;
    height: 79px;
  }

  .sprite-page_canvas-modular-pictures_90x80-modular-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -120px 0;
    width: 120px;
    height: 79px;
  }

  .sprite-page_canvas-modular-pictures_120x40-modular-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: 0 -79px;
    width: 120px;
    height: 37px;
  }

  .sprite-page_canvas-modular-pictures_120x40-modular-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -120px -79px;
    width: 120px;
    height: 37px;
  }

  .sprite-page_canvas-modular-pictures_110x50-modular-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: 0 -116px;
    width: 120px;
    height: 47px;
  }

  .sprite-page_canvas-modular-pictures_110x50-modular-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -120px -116px;
    width: 120px;
    height: 47px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_80x80-photograph-on-instacanvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -240px 0;
    width: 93px;
    height: 93px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_80x80-photograph-on-instacanvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: 0 -163px;
    width: 93px;
    height: 93px;
  }

  .sprite-page_canvas-photograph-on-canvas_80x80-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -93px -163px;
    width: 93px;
    height: 93px;
  }

  .sprite-page_canvas-photograph-on-canvas_80x80-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -186px -163px;
    width: 93px;
    height: 93px;
  }

  .sprite-page_canvas-photograph-on-canvas_80x60-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: 0 -256px;
    width: 93px;
    height: 71px;
  }

  .sprite-page_canvas-photograph-on-canvas_80x60-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -93px -256px;
    width: 93px;
    height: 71px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_25x25-photograph-on-instacanvas-set:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -333px 0;
    width: 87px;
    height: 86px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_25x25-photograph-on-instacanvas-set {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -333px -86px;
    width: 87px;
    height: 86px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_70x70-photograph-on-instacanvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -333px -172px;
    width: 83px;
    height: 83px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_70x70-photograph-on-instacanvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: 0 -327px;
    width: 83px;
    height: 83px;
  }

  .sprite-page_canvas-photograph-on-canvas_70x70-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -83px -327px;
    width: 83px;
    height: 83px;
  }

  .sprite-page_canvas-photograph-on-canvas_70x70-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -166px -327px;
    width: 83px;
    height: 83px;
  }

  .sprite-page_canvas-photograph-on-canvas_70x50-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -333px -255px;
    width: 83px;
    height: 63px;
  }

  .sprite-page_canvas-photograph-on-canvas_70x50-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -240px -93px;
    width: 83px;
    height: 63px;
  }

  .sprite-page_canvas-knurled-foam-board_70x50-knurled-foam-board-satin:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -186px -256px;
    width: 82px;
    height: 62px;
  }

  .sprite-page_canvas-knurled-foam-board_70x50-knurled-foam-board-satin {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -249px -327px;
    width: 82px;
    height: 62px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_15x15-photograph-on-instacanvas-set:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -420px 0;
    width: 74px;
    height: 73px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_15x15-photograph-on-instacanvas-set {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -420px -73px;
    width: 74px;
    height: 73px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_60x60-photograph-on-instacanvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -420px -146px;
    width: 73px;
    height: 73px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_60x60-photograph-on-instacanvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -420px -219px;
    width: 73px;
    height: 73px;
  }

  .sprite-page_canvas-photograph-on-canvas_60x60-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -420px -292px;
    width: 73px;
    height: 73px;
  }

  .sprite-page_canvas-photograph-on-canvas_60x60-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: 0 -410px;
    width: 73px;
    height: 73px;
  }

  .sprite-page_canvas-photograph-on-canvas_60x40-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -331px -327px;
    width: 73px;
    height: 53px;
  }

  .sprite-page_canvas-photograph-on-canvas_60x40-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -73px -410px;
    width: 73px;
    height: 53px;
  }

  .sprite-page_canvas-knurled-foam-board_60x40-knurled-foam-board-satin:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -146px -410px;
    width: 72px;
    height: 52px;
  }

  .sprite-page_canvas-knurled-foam-board_60x40-knurled-foam-board-satin {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -218px -410px;
    width: 72px;
    height: 52px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_50x50-photograph-on-instacanvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -494px 0;
    width: 63px;
    height: 63px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_50x50-photograph-on-instacanvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -494px -63px;
    width: 63px;
    height: 63px;
  }

  .sprite-page_canvas-photograph-on-canvas_50x50-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -494px -126px;
    width: 63px;
    height: 63px;
  }

  .sprite-page_canvas-photograph-on-canvas_50x50-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -494px -189px;
    width: 63px;
    height: 63px;
  }

  .sprite-page_canvas-photograph-on-canvas_50x40-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -494px -252px;
    width: 63px;
    height: 53px;
  }

  .sprite-page_canvas-photograph-on-canvas_50x40-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -494px -305px;
    width: 63px;
    height: 53px;
  }

  .sprite-page_canvas-knurled-foam-board_50x40-knurled-foam-board-satin:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -494px -358px;
    width: 62px;
    height: 52px;
  }

  .sprite-page_canvas-knurled-foam-board_50x40-knurled-foam-board-satin {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -494px -410px;
    width: 62px;
    height: 52px;
  }

  .sprite-page_canvas-knurled-foam-board_45x30-knurled-foam-board-satin:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -420px -365px;
    width: 57px;
    height: 42px;
  }

  .sprite-page_canvas-knurled-foam-board_45x30-knurled-foam-board-satin {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -268px -256px;
    width: 57px;
    height: 42px;
  }

  .sprite-page_canvas-knurled-foam-board_45x15-knurled-foam-board-matte:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -290px -410px;
    width: 57px;
    height: 27px;
  }

  .sprite-page_canvas-knurled-foam-board_45x15-knurled-foam-board-matte {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -347px -410px;
    width: 57px;
    height: 27px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_40x40-photograph-on-instacanvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -279px -163px;
    width: 53px;
    height: 53px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_40x40-photograph-on-instacanvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: 0 -483px;
    width: 53px;
    height: 53px;
  }

  .sprite-page_canvas-photograph-on-canvas_40x40-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -53px -483px;
    width: 53px;
    height: 53px;
  }

  .sprite-page_canvas-photograph-on-canvas_40x40-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -106px -483px;
    width: 53px;
    height: 53px;
  }

  .sprite-page_canvas-photograph-on-canvas_40x30-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -159px -483px;
    width: 53px;
    height: 43px;
  }

  .sprite-page_canvas-photograph-on-canvas_40x30-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -212px -483px;
    width: 53px;
    height: 43px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_30x30-photograph-on-instacanvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -265px -483px;
    width: 43px;
    height: 43px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_30x30-photograph-on-instacanvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -308px -483px;
    width: 43px;
    height: 43px;
  }

  .sprite-page_canvas-photograph-on-canvas_30x30-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -351px -483px;
    width: 43px;
    height: 43px;
  }

  .sprite-page_canvas-photograph-on-canvas_30x30-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -394px -483px;
    width: 43px;
    height: 43px;
  }

  .sprite-page_canvas-photograph-on-canvas_30x20-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -279px -216px;
    width: 43px;
    height: 33px;
  }

  .sprite-page_canvas-photograph-on-canvas_30x20-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -437px -483px;
    width: 43px;
    height: 33px;
  }

  .sprite-page_canvas-knurled-foam-board_30x21-knurled-foam-board-matte:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -480px -483px;
    width: 42px;
    height: 32px;
  }

  .sprite-page_canvas-knurled-foam-board_30x21-knurled-foam-board-matte {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px 0;
    width: 42px;
    height: 32px;
  }

  .sprite-page_canvas-knurled-foam-board_30x15-knurled-foam-board-matte:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px -32px;
    width: 42px;
    height: 27px;
  }

  .sprite-page_canvas-knurled-foam-board_30x15-knurled-foam-board-matte {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px -59px;
    width: 42px;
    height: 27px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_20x20-photograph-on-instacanvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px -86px;
    width: 35px;
    height: 35px;
  }

  .sprite-page_canvas-photograph-on-instacanvas_20x20-photograph-on-instacanvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px -121px;
    width: 35px;
    height: 35px;
  }

  .sprite-page_canvas-knurled-foam-board_21x15-knurled-foam-board-matte:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px -156px;
    width: 34px;
    height: 27px;
  }

  .sprite-page_canvas-knurled-foam-board_21x15-knurled-foam-board-matte {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px -183px;
    width: 34px;
    height: 27px;
  }

  .sprite-page_canvas-photograph-on-canvas_20x20-photograph-on-canvas:before {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px -210px;
    width: 33px;
    height: 33px;
  }

  .sprite-page_canvas-photograph-on-canvas_20x20-photograph-on-canvas {
    background-image: url('img/sprites/page_canvas.png');
    background-repeat: no-repeat;
    background-size: 599px 536px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_canvas.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_canvas@2x.png');
    }
    
    background-position: -557px -243px;
    width: 33px;
    height: 33px;
  }
