/* glue: 0.13 hash: 3dfa7db315 */

  .sprite-page_books-printbuk-premium_21x30_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: 0 0;
    width: 59px;
    height: 91px;
  }

  .sprite-page_books-printbuk-premium_21x30_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -59px 0;
    width: 59px;
    height: 91px;
  }

  .sprite-page_books-printbuk-premium_21x30_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -118px 0;
    width: 59px;
    height: 89px;
  }

  .sprite-page_books-printbuk-premium_21x30_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -177px 0;
    width: 59px;
    height: 89px;
  }

  .sprite-page_books-printbooks_21x30_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: 0 -91px;
    width: 57px;
    height: 87px;
  }

  .sprite-page_books-printbooks_21x30_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -57px -91px;
    width: 57px;
    height: 87px;
  }

  .sprite-page_books-printbooks_21x30_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -114px -91px;
    width: 57px;
    height: 86px;
  }

  .sprite-page_books-printbooks_21x30_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -171px -91px;
    width: 57px;
    height: 86px;
  }

  .sprite-page_books-printbuk-premium_30x21_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -236px 0;
    width: 81px;
    height: 70px;
  }

  .sprite-page_books-printbuk-premium_30x21_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -236px -70px;
    width: 81px;
    height: 70px;
  }

  .sprite-page_books-printbuk-premium_30x21_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: 0 -178px;
    width: 81px;
    height: 67px;
  }

  .sprite-page_books-printbuk-premium_30x21_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -81px -178px;
    width: 81px;
    height: 67px;
  }

  .sprite-page_books-printbooks_30x21_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -162px -178px;
    width: 79px;
    height: 65px;
  }

  .sprite-page_books-printbooks_30x21_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: 0 -245px;
    width: 79px;
    height: 65px;
  }

  .sprite-page_books-printbooks_30x21_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -79px -245px;
    width: 79px;
    height: 64px;
  }

  .sprite-page_books-printbooks_30x21_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -158px -245px;
    width: 79px;
    height: 64px;
  }

  .sprite-page_books-printbuk-premium_21x21_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -317px 0;
    width: 59px;
    height: 69px;
  }

  .sprite-page_books-printbuk-premium_21x21_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -317px -69px;
    width: 59px;
    height: 69px;
  }

  .sprite-page_books-printbuk-premium_15x21_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -317px -138px;
    width: 44px;
    height: 69px;
  }

  .sprite-page_books-printbuk-premium_15x21_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -317px -207px;
    width: 44px;
    height: 69px;
  }

  .sprite-page_books-printbuk-premium_21x21_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -376px 0;
    width: 59px;
    height: 67px;
  }

  .sprite-page_books-printbuk-premium_21x21_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -376px -67px;
    width: 59px;
    height: 67px;
  }

  .sprite-page_books-printbuk-premium_15x21_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -376px -134px;
    width: 44px;
    height: 65px;
  }

  .sprite-page_books-printbuk-premium_15x21_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -376px -199px;
    width: 44px;
    height: 65px;
  }

  .sprite-page_books-printbooks_21x21_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -241px -178px;
    width: 57px;
    height: 65px;
  }

  .sprite-page_books-printbooks_21x21_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: 0 -310px;
    width: 57px;
    height: 65px;
  }

  .sprite-page_books-printbooks_15x21_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -57px -310px;
    width: 42px;
    height: 65px;
  }

  .sprite-page_books-printbooks_15x21_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -99px -310px;
    width: 42px;
    height: 65px;
  }

  .sprite-page_books-printbooks_21x21_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -237px -245px;
    width: 57px;
    height: 64px;
  }

  .sprite-page_books-printbooks_21x21_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -141px -310px;
    width: 57px;
    height: 64px;
  }

  .sprite-page_books-printbooks_15x21_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -198px -310px;
    width: 42px;
    height: 64px;
  }

  .sprite-page_books-printbooks_15x21_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -240px -310px;
    width: 42px;
    height: 64px;
  }

  .sprite-page_books-printbuk-premium_21x15_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -282px -310px;
    width: 59px;
    height: 54px;
  }

  .sprite-page_books-printbuk-premium_21x15_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -341px -310px;
    width: 59px;
    height: 54px;
  }

  .sprite-page_books-printbuk-premium_21x15_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: 0 -375px;
    width: 59px;
    height: 52px;
  }

  .sprite-page_books-printbuk-premium_21x15_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -59px -375px;
    width: 59px;
    height: 52px;
  }

  .sprite-page_books-printbooks_21x15_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -118px -375px;
    width: 57px;
    height: 50px;
  }

  .sprite-page_books-printbooks_21x15_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -175px -375px;
    width: 57px;
    height: 50px;
  }

  .sprite-page_books-printbooks_21x15_hardcover:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -232px -375px;
    width: 57px;
    height: 49px;
  }

  .sprite-page_books-printbooks_21x15_hardcover {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -289px -375px;
    width: 57px;
    height: 49px;
  }

  .sprite-page_books-minibooks_10x15_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -435px 0;
    width: 37px;
    height: 56px;
  }

  .sprite-page_books-minibooks_10x15_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -435px -56px;
    width: 37px;
    height: 56px;
  }

  .sprite-page_books-minibooks_15x10_paperbacks:before {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -376px -264px;
    width: 50px;
    height: 45px;
  }

  .sprite-page_books-minibooks_15x10_paperbacks {
    background-image: url('img/sprites/page_books.png');
    background-repeat: no-repeat;
    background-size: 472px 427px;
    
    @media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi){
      background-image: url('img/sprites/page_books.png');
    }
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
      background-image: url('img/sprites/page_books@2x.png');
    }
    
    background-position: -346px -375px;
    width: 50px;
    height: 45px;
  }
